import { useEffect } from 'react'
import analytics from '../../../../domain/services/analytics/AnalyticsService'
import VoyagesScreenerContent from '../../components/voyages/VoyagesScreenerContent'
import { VoyagesScreenerProvider } from '../../providers/VoyagesScreenerProvider'
import secureScreen from '../../hooks/authentication/secureScreen'

const VoyagesScreenerScreen = () => {
  useEffect(() => {
    analytics.track('Screener Started')
  }, [])

  return (
    <VoyagesScreenerProvider>
      <VoyagesScreenerContent />
    </VoyagesScreenerProvider>
  )
}

export default secureScreen(VoyagesScreenerScreen)
