import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { FlatList, HStack, Text, View } from 'native-base'
import { useState } from 'react'
import { useDesktopBreakpoint } from '../../../domain/services/useBreakpoint'
import FilterPillContainer from '../components/explore/FilterPillContainer'
import ActivityCard from '../components/_shared/card/ActivityCard'
import ProfileContainer from '../components/_shared/layout/ProfileContainer'
import { useBookmark } from '../queries/activitiesQueries'
import secureScreen from '../hooks/authentication/secureScreen'
import { NavigationParams } from './NavigationScreen'

const BookmarksScreen = () => {
  const { bookmarks, unbookmark } = useBookmark()
  const isDesktop = useDesktopBreakpoint()
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()

  const [isEditing, setIsEditing] = useState(false)

  const handleUnbookmark = (id: string) => {
    unbookmark(id)
  }

  return (
    <ProfileContainer
      headerText="Your bookmarks"
      button={null}
      title="Your bookmarks"
      rightAction={
        <View mr={4}>
          <FilterPillContainer
            icon={isEditing ? 'check' : 'pencil'}
            isApplied={isEditing ? false : true}
            onPress={() => setIsEditing(!isEditing)}
            customBgColor="transparent"
            text={isEditing ? 'Done' : 'Edit'}
          />
        </View>
      }>
      {isDesktop && (
        <HStack justifyContent="space-between" mb={4}>
          <Text fontWeight="700" color="primary.900" fontSize="16px">
            Your bookmarks
          </Text>
          <FilterPillContainer
            icon={isEditing ? 'check' : 'pencil'}
            isApplied={isEditing ? false : true}
            onPress={() => setIsEditing(!isEditing)}
            customBgColor="transparent"
            text={isEditing ? 'Done' : 'Edit'}
          />
        </HStack>
      )}
      <FlatList
        mt={2}
        showsVerticalScrollIndicator={false}
        px={isDesktop ? 0 : 5}
        keyExtractor={(item) => item.id}
        numColumns={isDesktop ? 2 : 1}
        data={bookmarks}
        renderItem={({ item }) => {
          return (
            <View
              mb={4}
              mr={isDesktop ? 4 : 0}
              borderRadius="20px"
              width={isDesktop ? '300px' : '100%'}>
              <ActivityCard
                hasTrashIcon={isEditing}
                deleteOnPress={async () => handleUnbookmark(item.id)}
                reduceOpacityOnComplete={false}
                activity={item}
                onPress={async () =>
                  navigation.navigate('Activity', {
                    activityId: item.id,
                  })
                }
              />
            </View>
          )
        }}
      />
    </ProfileContainer>
  )
}

export default secureScreen(BookmarksScreen)
