import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Divider, HStack, Input } from 'native-base'
import { useEffect, useRef, useState } from 'react'
import { Platform } from 'react-native'
import { SearchBarProps } from '../../../../../domain/models/interfaces/explore/searchInterfaces'
import FaIcon from '../FaIcon'
import { NavigationParams } from '../../../screens/NavigationScreen'

const SearchBar = ({
  value,
  setValue,
  placeholder = null,
  autoFocused = false,
  showCancel = false,
  handleSubmit,
  handleFocus = null,
  handleCancel = null,
  isBeingFiltered = false,
}: SearchBarProps) => {
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()

  const input = useRef(null)
  const [isFocused, setIsFocused] = useState(autoFocused)

  useEffect(() => {
    if (isFocused) {
      input.current.focus()
    } else {
      input.current.blur()
    }
    if (handleFocus) handleFocus(isFocused)
  }, [isFocused])

  const cancel = () => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.replace('Explore')
      handleCancel()
    }
  }

  const userPressesEnter = () => {
    if (Platform.OS !== 'web' && !value && !isBeingFiltered) {
      setIsFocused(false)
    }
  }

  return (
    <HStack w="100%">
      <Input
        ref={input}
        value={value}
        placeholder={placeholder}
        size="lg"
        returnKeyType="search"
        flex="1"
        bgColor="white"
        InputLeftElement={
          showCancel && (
            <FaIcon
              name="chevron-left"
              size={20}
              props={{ pl: '2' }}
              onPress={showCancel ? () => cancel() : undefined}
            />
          )
        }
        InputRightElement={
          <HStack alignItems="center">
            {!!value && (
              <FaIcon
                name="xmark"
                size={20}
                props={{ pr: '2' }}
                onPress={() => setValue('')}
              />
            )}
            <Divider w="1px" h="24px" bg="muted.500" orientation="vertical" />
            <FaIcon
              name="magnifying-glass"
              color="primary.700"
              size={20}
              props={{ px: '2' }}
              onPress={() => handleSubmit()}
            />
          </HStack>
        }
        onChangeText={(newValue) => setValue(newValue)}
        onSubmitEditing={() => handleSubmit()}
        onFocus={() => setIsFocused(true)}
        onBlur={() => userPressesEnter()}
      />
    </HStack>
  )
}

export default SearchBar
