import { useEffect } from "react"
import useAuthentication from "../hooks/authentication/useAuthentication"
import { Linking } from "react-native"

const LoginWithPasswordScreen = () => {
  console.log(Linking.getInitialURL())

  const { loginWithPassword } = useAuthentication()
  useEffect(() => {
    loginWithPassword()
  })
  
  return <></>
}

export default LoginWithPasswordScreen