import {
  Box,
  Divider,
  FlatList,
  ScrollView,
  StatusBar,
  Text,
  View,
  VStack,
} from 'native-base'
import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import ModeratorCard from '../../components/moderators/ModeratorCard'
import Header from '../../components/_shared/Header'
import FixedContainer from '../../components/_shared/layout/FixedContainer'
import { SkeletonLoader } from '../../components/_shared/SkeletonLoader'
import DefaultLayout from '../../layouts/DefaultLayout'
import { useModerators } from './queries'
import secureScreen from '../../hooks/authentication/secureScreen'

const LoadingView = () => (
  <VStack justifyContent="center" alignItems="center" p={6}>
    <SkeletonLoader mb={6} size={{ width: '100%', height: '88px' }} />
    <SkeletonLoader mb={6} size={{ width: '100%', height: '88px' }} />
    <SkeletonLoader mb={6} size={{ width: '100%', height: '88px' }} />
    <SkeletonLoader mb={6} size={{ width: '100%', height: '88px' }} />
    <SkeletonLoader mb={6} size={{ width: '100%', height: '88px' }} />
  </VStack>
)

const ModeratorsScreen = () => {
  const { data, isLoading } = useModerators('name')
  const isMobile = useMobileBreakpoint()

  const renderModerators = () => (
    <FlatList
      flex={1}
      data={data}
      px={isMobile ? 0 : 6}
      renderItem={({ item, index }) => (
        <ModeratorCard
          width={isMobile ? '90%' : '100%'}
          moderator={item}
          firstCard={index === 0}
          isModeratorWebScreen={!isMobile}
        />
      )}
      keyExtractor={(_, index) => index.toString()}
    />
  )

  return (
    <DefaultLayout activeLabel="Explore" scrollView={isMobile ? false : true}>
      {isMobile ? (
        <VStack flex={1}>
          <StatusBar barStyle="light-content" />
          <Header title="Moderators" backButtonTitle="Explore" />
          <ScrollView flex={1}>
            {isLoading ? <LoadingView /> : renderModerators()}
          </ScrollView>
        </VStack>
      ) : (
        <FixedContainer width="1024px" size="lg">
          <Box
            mt={10}
            marginBottom={4}
            borderColor="grey.200"
            borderWidth={1}
            backgroundColor="white"
            borderRadius="20px"
            pb={6}>
            <View px={6}>
              <Text fontWeight="bold" pt="24px" pb="6px">
                Moderators
              </Text>
              <Divider />
            </View>
            {isLoading ? <LoadingView /> : renderModerators()}
          </Box>
        </FixedContainer>
      )}
    </DefaultLayout>
  )
}

export default secureScreen(ModeratorsScreen)
