import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import moment from 'moment'
import { Button, HStack, Image, Pressable, Text, VStack } from 'native-base'
import { groupDateFormat } from '../../../../../domain/models/interfaces/activityInterfaces'
import useCardData from '../../../hooks/useCardData'
import { useBookmark } from '../../../queries/activitiesQueries'
import Chip from '../../_shared/card/Chip'
import TodayBadge from '../../_shared/card/TodayBadge'
import FaIcon from '../../_shared/FaIcon'
import { NavigationParams } from '../../../screens/NavigationScreen'

interface ActivityHeader {
  id: string
  title: string
  duration: number
  moderator: {
    name: string
    photoUrl: string
  }
  type: string
  bookmarked: boolean
  startDate?: string
  endDate?: string
  cardBorderState?: string
}

interface ContentHeaderProps {
  activityHeader: ActivityHeader
}

const ContentHeader = ({ activityHeader }: ContentHeaderProps) => {
  const isBookmarked = activityHeader.bookmarked ? 'Bookmarked' : 'Bookmark'
  const { bookmark, unbookmark } = useBookmark(activityHeader.id)
  const { typeData } = useCardData(
    activityHeader.type,
    activityHeader.cardBorderState
  )
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()

  const validDate =
    (activityHeader.startDate as any) instanceof Date &&
    !isNaN(new Date(activityHeader.startDate).getTime())

  const handleBookmark = () => {
    if (isBookmarked === 'Bookmarked') {
      unbookmark()
    } else {
      bookmark()
    }
  }

  return (
    <VStack>
      <HStack alignItems="center" justifyContent="space-between" mb={2}>
        <HStack>
          <Chip type={activityHeader.type} />
          <Chip
            type="Duration"
            duration={activityHeader.duration}
            textTransform="lowercase"
            fontWeight={400}
          />
        </HStack>
        <Button variant="unstyled" p={0} onPress={handleBookmark}>
          <Chip
            type={isBookmarked}
            iconColor="primary.600"
            textTransform="capitalize"
            fontWeight={400}
          />
        </Button>
      </HStack>
      <Text fontSize="2xl" color="primary.900" fontWeight="bold">
        {activityHeader.title}
      </Text>
      {validDate && (
        <HStack alignItems="center" my={2}>
          {!moment(activityHeader.startDate).isSame(moment(), 'day') && (
            <HStack mr={2}>
              <FaIcon
                name="alarm-clock"
                isSolid
                color={`${typeData.color}.600`}
                size={16}
              />
            </HStack>
          )}
          <TodayBadge date={activityHeader.startDate} typeData={typeData} />
          <Text color={`${typeData.color}.600`}>
            {groupDateFormat(new Date(activityHeader.startDate))}
          </Text>
        </HStack>
      )}
      <Pressable
        onPress={() => {
          navigation.navigate('ModeratorDetail', {
            moderatorId: activityHeader.moderator.id,
          })
        }}>
        <HStack alignItems="center" space="2" my={2}>
          <Image
            source={{ uri: activityHeader.moderator.photoUrl }}
            alt="Moderator photo"
            size="32px"
            borderRadius="full"
          />
          <Text
            ellipsizeMode="tail"
            numberOfLines={1}
            fontSize="sm"
            color="primary.900">
            {activityHeader.moderator.name}
          </Text>
        </HStack>
      </Pressable>
    </VStack>
  )
}

export default ContentHeader
