import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { requestMicrophonePermissionsAsync } from 'expo-camera'
import {
  Button,
  Center,
  Heading,
  HStack,
  IconButton,
  Input,
  KeyboardAvoidingView,
  ScrollView,
  Text,
  Tooltip,
  VStack,
} from 'native-base'
import { useCallback, useEffect, useState } from 'react'
import { Platform, useWindowDimensions } from 'react-native'
import AttendeeRole from '../../../../../domain/enums/AttendeeRole'
import {
  useDesktopBreakpoint,
  useMobileBreakpoint,
} from '../../../../../domain/services/useBreakpoint'
import useGetShadowProps from '../../../hooks/useGetShadowProps'
import useInvalidateQueries from '../../../hooks/useInvalidateQueries'
import useMobileKeyboardListeners from '../../../hooks/useMobileKeyboardListeners'
import WaveLayout from '../../../layouts/WaveLayout'
import { useCabanaApi } from '../../../providers/CabanaApiProvider'
import useRoomClientContext from '../../../providers/RoomClientProvider'
import { useRoomContext } from '../../../providers/RoomProvider'
import Card from '../../_shared/card/Card'
import FaIcon from '../../_shared/FaIcon'
import StarRating from '../../_shared/form/StarRating'
import ProgressModal from '../../_shared/ProgressModal'
import { NavigationParams } from '../../../screens/NavigationScreen'

const RoomFeedback = ({}) => {
  if (Platform.OS === 'web') {
    return (
      <WaveLayout>
        <RoomFeedbackView />
      </WaveLayout>
    )
  }
  return <RoomFeedbackView />
}

const RoomFeedbackView = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const { RoomApi } = useCabanaApi()
  const room = useRoomContext()
  const roomClient = useRoomClientContext()
  const [thumbsUp, setThumbsUp] = useState(null)
  const [overallRating, setOverallRating] = useState(null)
  const [technologyRating, setTechnologyRating] = useState(null)
  const [groupCohesionRating, setGroupCohesionRating] = useState(null)
  const [moderatorRating, setModeratorRating] = useState(null)
  const [comments, setComments] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const shadowProps = useGetShadowProps()
  const isMobile = useMobileBreakpoint()
  const { height } = useWindowDimensions()
  const { keyboardOpen, closeKeyboard } = useMobileKeyboardListeners()
  const { invalidateQueries } = useInvalidateQueries()
  const desktop = useDesktopBreakpoint()

  const rejoin = async () => {
    if (Platform.OS === 'ios') {
      const permissionResponse = await requestMicrophonePermissionsAsync()
      if (!permissionResponse.granted) {
        RoomApi.setParticipantRole(room.roomId, AttendeeRole.Spectator)
      } else {
        RoomApi.setParticipantRole(room.roomId, AttendeeRole.Participant)
      }
    }
    RoomApi.join(room.roomId)
  }

  const goHome = () => {
    navigation.replace('Home')
  }

  const minBottomPadding = 75
  const mobilePadding = isMobile ? 90 : 0
  const minDeviceHeight = 640 + mobilePadding
  const bottomPadding =
    height - minDeviceHeight < minBottomPadding
      ? minBottomPadding
      : height - minDeviceHeight

  const submitFeedback = () => {
    setIsSubmitting(true)
  }

  const setCommentWithoutEnter = useCallback(
    (text) => {
      text = text.replace(/(\r\n|\n|\r)/gm, '')
      setComments(text)
    },
    [setComments]
  )

  const onKeyPress = useCallback(
    (event) => {
      if (event.nativeEvent.key === 'Enter') {
        event.preventDefault()
        closeKeyboard()
      }
    },
    [closeKeyboard]
  )

  useEffect(() => {
    invalidateQueries('Activity')
  }, [])

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : null}
      style={{ flex: 1 }}
      keyboardVerticalOffset={bottomPadding / 2 < 50 ? 0 : -bottomPadding / 2}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          flex: 1,
          justifyContent: 'flex-start',
        }}
        scrollEnabled={true}
        bounces={false}>
        <Center
          pt={isMobile ? (keyboardOpen ? undefined : '24px') : '100px'}
          pb={bottomPadding + 'px'}>
          <Heading
            textAlign="center"
            pb={room.me.role == AttendeeRole.Moderator ? '32px' : undefined}>
            {room.me.role === AttendeeRole.Moderator
              ? 'Rate today’s group'
              : `Thank you${isMobile ? '\n' : ' '}for attending!`}
          </Heading>
          {room.me.role != AttendeeRole.Moderator && (
            <HStack space="3" mt="5" mb="8">
              <Tooltip
                label="Oops! The group has ended"
                isDisabled={!room.ended}
                placement="top">
                <Button
                  colorScheme="primary"
                  variant="outline"
                  onPress={rejoin}
                  isDisabled={room.ended}>
                  Rejoin
                </Button>
              </Tooltip>
              <Button colorScheme="primary" onPress={goHome}>
                Return to home
              </Button>
            </HStack>
          )}
          <Card
            boxProps={{
              ...shadowProps,
              h: Platform.OS === 'web' ? undefined : 523,
              p: '16px',
            }}
            body={
              <VStack flex={1}>
                <Center>
                  {room.me.role != AttendeeRole.Moderator && (
                    <Heading size="md">
                      Do you feel better
                      {'\n'}
                      after this group?
                    </Heading>
                  )}
                  <HStack>
                    <IconButton
                      icon={
                        <FaIcon
                          name="thumbs-up"
                          size={24}
                          color="success.500"
                        />
                      }
                      isHovered={thumbsUp}
                      colorScheme="success"
                      borderRadius="full"
                      onPress={() => setThumbsUp(true)}
                    />
                    <IconButton
                      icon={
                        <FaIcon
                          name="thumbs-down"
                          size={24}
                          color="error.500"
                        />
                      }
                      isHovered={thumbsUp === false}
                      colorScheme="error"
                      borderRadius="full"
                      onPress={() => setThumbsUp(false)}
                    />
                  </HStack>
                </Center>
                <VStack mt="24px">
                  <StarRating
                    label="Overall"
                    rating={overallRating}
                    setRating={setOverallRating}
                  />
                  <StarRating
                    label="Technology"
                    rating={technologyRating}
                    setRating={setTechnologyRating}
                  />
                  {room.me.role == AttendeeRole.Moderator && (
                    <StarRating
                      label="Group cohesion"
                      rating={groupCohesionRating}
                      setRating={setGroupCohesionRating}
                    />
                  )}
                  {room.me.role !== AttendeeRole.Moderator && (
                    <StarRating
                      label="Moderator"
                      rating={moderatorRating}
                      setRating={setModeratorRating}
                    />
                  )}
                </VStack>

                <Input
                  flex={1}
                  size="lg"
                  maxHeight={96}
                  scrollEnabled
                  placeholder="Comments"
                  autoCompleteType={undefined}
                  mt="70px"
                  value={comments}
                  onChangeText={setCommentWithoutEnter}
                  returnKeyType="done"
                  pb="55px"
                  multiline={true}
                  onKeyPress={onKeyPress}
                />
                <Button
                  colorScheme="primary"
                  w="100%"
                  mt="32px"
                  onPress={submitFeedback}>
                  Submit
                </Button>
              </VStack>
            }
          />
        </Center>
      </ScrollView>
      <ProgressModal
        downloadProgress={isSubmitting ? 100 : 0}
        isVisible={isSubmitting}
        successMessage={
          <>
            <Text
              fontWeight="700"
              color="text.900">{`Feedback submitted!`}</Text>
            <Text>
              {`\nYou’re being redirected to the live group `}
              <Text underline>{`details`}</Text>
              {` page.`}
            </Text>
          </>
        }
        animationDuration={2000}
        position={
          isMobile
            ? {
                top: 520,
              }
            : { top: 0, right: 0 }
        }
        callback={() => {
          setIsSubmitting(false)
          if (desktop) {
            navigation.navigate('Activity', { activityId: room.activityId })
          } else {
            navigation.navigate('Home')
          }
          if (room.me.role == AttendeeRole.Moderator) {
            const feedback = {
              thumbsUp,
              overallRating,
              technologyRating,
              groupCohesionRating,
              comments,
            }
            RoomApi.submitModeratorFeedback(room.roomId, feedback)
          } else {
            const feedback = {
              thumbsUp,
              overallRating,
              technologyRating,
              moderatorRating,
              comments,
            }
            RoomApi.submitParticipantFeedback(room.roomId, feedback)
          }
        }}
      />
    </KeyboardAvoidingView>
  )
}

export default RoomFeedback
