import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import Completed from '../../components/voyages/Completed'
import FixedContainer from '../../components/_shared/layout/FixedContainer'
import DefaultLayout from '../../layouts/DefaultLayout'
import secureScreen from '../../hooks/authentication/secureScreen'

const CompletedScreen = () => {
  const isDesktop = useDesktopBreakpoint()

  return (
    <DefaultLayout
      hasNavFooter={false}
      scrollView={isDesktop ? true : false}
      activeLabel="Voyage"
      bg={isDesktop ? 'muted.50' : 'white'}>
      {isDesktop ? (
        <FixedContainer size="md" width="1024px">
          <Completed />
        </FixedContainer>
      ) : (
        <Completed />
      )}
    </DefaultLayout>
  )
}

export default secureScreen(CompletedScreen)
