import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { HStack, IconButton, Pressable, Text } from 'native-base'
import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import useAuthentication from '../../hooks/authentication/useAuthentication'
import FaIcon from '../_shared/FaIcon'
import { NavigationParams } from '../../screens/NavigationScreen'

const BackButton = ({
  isDisabled = false,
  color = 'primary.700',
  hasText = null,
  customText = null,
  onPress = null,
  style = null,
}) => {
  const isMobile = useMobileBreakpoint()
  const navigation =
    useNavigation<
      NativeStackNavigationProp<NavigationParams>
    >()
  const { user } = useAuthentication()

  const goBack = () => {
    if (navigation.canGoBack()) navigation.goBack()
    else if (user) navigation.replace('Home')
    else navigation.replace('Landing')
  }

  const hasBackText = hasText ?? !isMobile

  return (
    <Pressable
      opacity={isDisabled ? '0' : '1'}
      onPress={isDisabled ? undefined : onPress ?? goBack}
      pt={'9px'}
      mr="auto"
      style={style}>
      <HStack alignItems="center" zIndex="3">
        {customText === '' || hasBackText ? (
          <IconButton
            ml={-2}
            onPress={isDisabled ? undefined : onPress ?? goBack}
            borderRadius="full"
            icon={<FaIcon name="chevron-left" color={color} size={20} />}
          />
        ) : (
          <FaIcon name="chevron-left" color={color} size={20} />
        )}

        {hasBackText ||
          (isMobile && (
            <Text
              pl="2"
              fontSize="17"
              fontWeight="medium"
              color={color}
              selectable={false}>
              {customText ?? 'Back'}
            </Text>
          ))}
      </HStack>
    </Pressable>
  )
}

export default BackButton
