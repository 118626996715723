import AsyncStorage from '@react-native-async-storage/async-storage'
import { useEffect, useMemo } from 'react'
import { Platform } from 'react-native'
import {
  ActivityType,
  GroupTypes,
} from '../../../domain/models/interfaces/activityInterfaces'
import analytics from '../../../domain/services/analytics/AnalyticsService'
import { useMobileBreakpoint } from '../../../domain/services/useBreakpoint'
import ActivityContentDesktop from '../components/explore/activities/ActivityContentDesktop'
import ActivityContentMobile from '../components/explore/activities/ActivityContentMobile'
import { useCompleteDailyRec } from '../components/home/queries'
import { ModalProvider } from '../providers/ModalProvider'
import { useGetActivityDetails } from '../queries/activitiesQueries'
import secureScreen from '../hooks/authentication/secureScreen'

const isDefaultDailyRecActivity = (activity) => {
  if (!activity) return false
  return (
    ![ActivityType.VIDEO, ActivityType.AUDIO].includes(activity.type) &&
    !GroupTypes.includes(activity.type) &&
    !activity.downloadUrl
  )
}

const ActivityScreen = ({ route }) => {
  const { activityId, autoPlay } = route.params
  const { activity, isLoading } = useGetActivityDetails(activityId)
  const isMobile = useMobileBreakpoint()
  const { completeContent } = useCompleteDailyRec()

  useEffect(() => {
    const removeRedirectUrl = async () => {
      await AsyncStorage.getItem('redirectUrl').then(() => {
        AsyncStorage.removeItem('redirectUrl')
      })
    }
    if (Platform.OS === 'web') {
      removeRedirectUrl()
    }
  }, [])

  useEffect(() => {
    if (isLoading) return

    if (isDefaultDailyRecActivity(activity)) {
      completeContent(activity)
    }

    const { eventName, eventData } = analyticsPayload(activity)
    analytics.track(eventName, eventData)
  }, [isLoading])

  const analyticsPayload = (activity) => {
    if (isGroup(activity)) {
      return {
        eventName: 'Group Viewed',
        eventData: {
          ID: activity.id,
          Title: activity.title,
          'Group Type': activity.type,
          Moderator: activity.moderator.name,
        },
      }
    } else if (isContent(activity)) {
      return {
        eventName: 'Content Viewed',
        eventData: {
          'Activity ID': activity.id,
          'Activity Title': activity.title,
          'Content Type': activity.type,
          Duration: activity.duration,
        },
      }
    }
  }

  const isGroup = (activity) => {
    switch (activity.type) {
      case ActivityType.GROUP_LISTEN:
      case ActivityType.GROUP_SKILL:
      case ActivityType.GROUP_SERIES:
      case ActivityType.GROUP_SUPPORT:
      case ActivityType.GROUP_OTHER:
        return true
      default:
        return false
    }
  }
  const isContent = (activity) => {
    switch (activity.type) {
      case ActivityType.ARTICLE:
      case ActivityType.AUDIO:
      case ActivityType.VIDEO:
      case ActivityType.ACTIVITY:
      case ActivityType.MOMENT:
      case ActivityType.QUIZ:
      case ActivityType.INTERACTIVE:
        return true
      default:
        return false
    }
  }

  const activityContent = useMemo(() => {
    if (!isMobile) {
      return (
        <ActivityContentDesktop activity={activity} isLoading={isLoading} />
      )
    } else {
      return (
        <ActivityContentMobile
          activity={activity}
          isLoading={isLoading}
          autoPlay={autoPlay}
        />
      )
    }
  }, [activity, isLoading, autoPlay])
  if (isLoading) return null
  return <ModalProvider activity={activity}>{activityContent}</ModalProvider>
}

export default secureScreen(ActivityScreen)
