import { VStack } from 'native-base'
import Leaderboard from '../../components/profile/Leaderboard/Leaderboard'
import {
  useAllTimeLeaderboard,
  useMonthlyLeaderboard,
} from '../../queries/leaderboardQueries'
import secureScreen from '../../hooks/authentication/secureScreen'

const LeaderboardScreen = () => {
  const { data, isLoading } = useAllTimeLeaderboard()
  const { data: monthlyData, isLoading: isLoadingMonthlyData } =
    useMonthlyLeaderboard()

  return (
    <VStack ml={-6}>
      <Leaderboard
        data={monthlyData}
        type="monthly"
        isLoading={isLoadingMonthlyData}
      />
      <Leaderboard data={data} isLoading={isLoading} />
    </VStack>
  )
}

export default secureScreen(LeaderboardScreen)
