import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import CompassForm from '../../components/voyages/compass/CompassForm'
import FixedContainer from '../../components/_shared/layout/FixedContainer'
import DefaultLayout from '../../layouts/DefaultLayout'
import secureScreen from '../../hooks/authentication/secureScreen'

const CompassFormScreen = () => {
  const isDesktop = useDesktopBreakpoint()

  return (
    <DefaultLayout
      hasNavFooter={false}
      scrollView={isDesktop ? true : false}
      activeLabel="Voyage"
      bg={isDesktop ? 'muted.50' : 'white'}>
      {isDesktop ? (
        <FixedContainer size="md" width="1024px">
          <CompassForm />
        </FixedContainer>
      ) : (
        <CompassForm />
      )}
    </DefaultLayout>
  )
}

export default secureScreen(CompassFormScreen)
