import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import {
  Box,
  Button,
  HStack,
  Image,
  Input,
  ScrollView,
  Text,
} from 'native-base'
import { useEffect, useState } from 'react'
import { Platform } from 'react-native'
import analytics from '../../../../../domain/services/analytics/AnalyticsService'
import { useDesktopBreakpoint } from '../../../../../domain/services/useBreakpoint'
import { capitalize } from '../../../../helpers/capitalizeString'
import { useCabanaApi } from '../../../providers/CabanaApiProvider'
import BackButton from '../../navigation/BackButton'
import { NavigationParams } from '../../../screens/NavigationScreen'

const CompassImage = require('../../../assets/images/voyages/compass-bw.png')

const CompassForm = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const isDesktop = useDesktopBreakpoint()
  const { VoyageApi } = useCabanaApi()

  useEffect(() => {
    analytics.track('Values Started')
  }, [])

  const [input, setInput] = useState('')
  const [values, setValues] = useState({
    north: '',
    east: '',
    south: '',
    west: '',
  })
  const [currentDirection, setCurrentDirection] = useState('north')

  const directionsOrder = ['north', 'east', 'south', 'west']

  const handleInputChange = (value) => {
    setInput(value)
    setValues((prevState) => ({ ...prevState, [currentDirection]: value }))
  }

  const handleButtonClick = () => {
    setInput('')
    if (currentDirection === 'west') {
      handleSubmit()
    } else {
      const currentIndex = directionsOrder.indexOf(currentDirection)
      const nextIndex = (currentIndex + 1) % directionsOrder.length
      setCurrentDirection(directionsOrder[nextIndex])
    }
  }

  const description =
    (currentDirection === 'north' &&
      "Your guiding star, an integral part of your being. It represents your true north, the unwavering principle that directs your life's journey.") ||
    (currentDirection === 'east' &&
      'Values you seek in others.  Think of your family, friends, and co-workers.') ||
    (currentDirection === 'south' &&
      'The value that requires me to push myself the most, something very important to me but that does not necessarily come naturally.') ||
    (currentDirection === 'west' &&
      'Values you seek from your job, brand, or company.')

  const handleSubmit = () => {
    VoyageApi.completeCompass(values)
      .then(() => {
        navigation.navigate('CompassResults')
      })
      .catch((err) => console.error(err))
  }

  return (
    <>
      {!isDesktop ? (
        <HStack
          ml={3}
          width="100%"
          alignItems="flex-end"
          justifyContent="space-between"
          height={98}
          backgroundColor="white"
          borderBottomWidth={1}
          shadow={0.5}
          borderBottomColor="muted.50"
          pb={2}>
          <BackButton customText="" />
        </HStack>
      ) : (
        <Box>
          <BackButton customText="" />
        </Box>
      )}
      <ScrollView
        borderRadius={isDesktop ? '20px' : 0}
        shadow={isDesktop ? 4 : 0}
        my={isDesktop ? 2 : 0}
        backgroundColor="white"
        pt={isDesktop ? 10 : 6}
        p={isDesktop ? 10 : 4}
        showsVerticalScrollIndicator={false}>
        <Box display="flex" flexDirection={isDesktop ? 'row' : 'column'}>
          <Box width={!isDesktop ? '100%' : '60%'}>
            <Text mb={2} fontSize="24px" color="primary.900" fontWeight={500}>
              {`${capitalize(currentDirection)} Star`}
            </Text>
            <Text mb={10} color="primary.900">
              {description}
            </Text>
            <Input
              width={!isDesktop ? '100%' : '342px'}
              alignSelf="center"
              size="lg"
              value={input}
              placeholder={`Enter ${currentDirection}`}
              onChangeText={handleInputChange}
            />
            <Button
              width={!isDesktop ? '100%' : '342px'}
              alignSelf="center"
              mt={10}
              onPress={handleButtonClick}
              isDisabled={!input}>
              <Text color="white" fontSize="16px" fontWeight={500}>
                Add to compass
              </Text>
            </Button>
            {currentDirection !== 'north' && (
              <Button
                variant="unstyled"
                borderWidth={2}
                width="175px"
                alignSelf="center"
                borderColor="muted.200"
                mt={3}
                onPress={handleButtonClick}>
                <Text fontSize="14px" fontWeight={500}>
                  {`Skip ${currentDirection} for now`}
                </Text>
              </Button>
            )}
          </Box>
          {isDesktop && (
            <Box width={!isDesktop ? '100%' : '40%'}>
              <Image
                mt={2}
                mb={3}
                alignSelf="center"
                style={{ height: 300, width: 300 }}
                source={CompassImage}
                alt="Compass"
              />
            </Box>
          )}
        </Box>
        {Platform.OS === 'ios' && <Box height="50px" />}
      </ScrollView>
    </>
  )
}

export default CompassForm
