import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Button, ScrollView, StatusBar, Text } from 'native-base'
import ProfileContainer from '../_shared/layout/ProfileContainer'
import WeeklyInsightsContainer from './WeeklyInsightsContainer'
import { NavigationParams } from '../../screens/NavigationScreen'

const WeeklyInsights = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()

  return (
    <ProfileContainer
      headerText="Weekly insights"
      title="Weekly insights"
      button={
        <Button
          p={0}
          variant="unstyled"
          onPress={() => navigation.navigate('MentalHealth')}>
          <Text color="secondary.700" fontWeight={700} fontSize="16px">
            Edit goals
          </Text>
        </Button>
      }>
      <StatusBar barStyle="dark-content" />
      <ScrollView p={5} showsVerticalScrollIndicator={false} flex={1}>
        <WeeklyInsightsContainer />
      </ScrollView>
    </ProfileContainer>
  )
}

export default WeeklyInsights
