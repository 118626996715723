import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Heading, HStack, Pressable, Text, VStack } from 'native-base'
import { FlatList } from 'react-native'
import { ModeratorsExploreProps } from '../../../../domain/models/interfaces/explore/moderatorsInterfaces'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import ModeratorCard from '../moderators/ModeratorCard'
import { NavigationParams } from '../../screens/NavigationScreen'

const ModeratorsExplore = ({ data }: ModeratorsExploreProps) => {
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const isDesktop = useDesktopBreakpoint()
  return (
    <VStack>
      <HStack justifyContent="space-between" width="90%" alignSelf="center">
        <Heading size="sm" color="primary.900">
          Moderators
        </Heading>
        <Pressable onPress={() => navigation.navigate('Moderators')}>
          <Text color="primary.900">see all</Text>
        </Pressable>
      </HStack>
      <FlatList
        contentContainerStyle={{ flex: 1, justifyContent: 'center' }}
        horizontal={isDesktop ? true : false}
        showsHorizontalScrollIndicator={isDesktop ? true : false}
        data={data}
        renderItem={({ item, index }) => (
          <ModeratorCard
            moderator={item}
            width={isDesktop ? '300px' : '90%'}
            firstCard={index === 0}
          />
        )}
        keyExtractor={(item) => item.id}
      />
    </VStack>
  )
}

export default ModeratorsExplore
