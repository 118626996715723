import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Button, Divider, FlatList, HStack, Text, View } from 'native-base'
import {
  ActivityCardBorderState,
  Group,
} from '../../../../domain/models/interfaces/activityInterfaces'
import useGetShadowProps from '../../hooks/useGetShadowProps'
import { useMyGroups } from '../../queries/activitiesQueries'
import EmptyListComponent from '../EmptyListComponent'
import ActivityCard from '../_shared/card/ActivityCard'
import { SkeletonLoader } from '../_shared/SkeletonLoader'
import secureScreen from '../../hooks/authentication/secureScreen'
import { NavigationParams } from '../../screens/NavigationScreen'

const EmptyImage = require('../../assets/images/vibe-check/empty-groups.png')

export const parseSections = (groups: Group[]) => {
  if (!groups) return [[], []]
  const currentDate = new Date()
  const upcomingGroups = groups.filter((group) => group.endDate >= currentDate)
  const pastGroups = groups
    .filter((group) => group.endDate < currentDate)
    .slice(0, 2)
  return [upcomingGroups, pastGroups]
}

const MyGroups = () => {
  const { registeredGroups, isLoading } = useMyGroups()
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const shadow = useGetShadowProps(15)
  const [upcomingGroups, pastGroups] = parseSections(registeredGroups)

  const borderStyle = {
    borderColor: 'primary.700',
    borderWidth: '1',
    borderRadius: '10px',
  }

  const borderStyleLive = {
    borderColor: 'tertiary.500',
    borderWidth: '1',
    borderRadius: '10px',
  }

  return (
    <HStack
      borderRadius="2xl"
      p={4}
      borderColor="grey.200"
      borderWidth={1}
      mx={5}
      mt={5}
      bg="white"
      flexDirection="column">
      {isLoading && <SkeletonLoader size={{ width: '100%', height: 300 }} />}
      {upcomingGroups.length > 0 && !isLoading && (
        <View flex={1}>
          <Text fontSize="16px" color="primary.900" fontWeight={700} mb={6}>
            My upcoming groups
          </Text>
          <FlatList
            showsVerticalScrollIndicator={false}
            data={upcomingGroups}
            renderItem={({ item }) => {
              return (
                <View my={5} bg="white">
                  <ActivityCard
                    hasShadow={false}
                    borderStyle={
                      item.cardBorderState === ActivityCardBorderState.LIVE
                        ? borderStyleLive
                        : borderStyle
                    }
                    reduceOpacityOnComplete={false}
                    activity={item}
                    onPress={async () =>
                      navigation.navigate('Activity', {
                        activityId: item.id,
                      })
                    }
                  />
                </View>
              )
            }}
          />
          <Divider mb={4} color="muted.300" />
        </View>
      )}
      {pastGroups.length > 0 && !isLoading && (
        <View flex={1}>
          <Text fontSize="16px" color="primary.900" fontWeight={700} mb={6}>
            My past groups
          </Text>
          <FlatList
            data={pastGroups}
            renderItem={({ item }) => {
              return (
                <View mb={4} bg="white">
                  <ActivityCard
                    hasShadow={false}
                    borderStyle={borderStyle}
                    reduceOpacityOnComplete={false}
                    activity={item}
                    onPress={async () =>
                      navigation.navigate('Activity', {
                        activityId: item.id,
                      })
                    }
                  />
                </View>
              )
            }}
          />
          <Button
            variant="link"
            alignSelf="flex-end"
            mt={-2}
            onPress={() => navigation.navigate('MyGroups')}>
            <Text
              color="primary.900"
              textDecorationLine="underline"
              fontSize="12px">
              View all
            </Text>
          </Button>
        </View>
      )}
      {upcomingGroups.length === 0 && pastGroups.length === 0 && !isLoading && (
        <View px={4} py={5} mb={4}>
          <EmptyListComponent
            description="Participate or register in a live-group to view history"
            buttonText="View upcoming groups"
            buttonAction={() => navigation.navigate('LiveGroups')}
            imageSource={EmptyImage}
          />
        </View>
      )}
    </HStack>
  )
}

export default secureScreen(MyGroups)
