import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import {
  Box,
  Button,
  HStack,
  Image,
  ScrollView,
  StatusBar,
  Text,
} from 'native-base'
import { StyleSheet, Text as TextNative } from 'react-native'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import Header from '../_shared/Header'
import { NavigationParams } from '../../screens/NavigationScreen'

const styles = StyleSheet.create({
  italic: {
    fontFamily: 'Poppins_700Bold_Italic',
  },
})

const About = require('../../assets/images/voyages/about.png')

const VoyagesAboutYou = ({ onPress }) => {
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const isDesktop = useDesktopBreakpoint()
  return (
    <>
      <StatusBar barStyle="dark-content" />
      {!isDesktop && (
        <Box
          mb={4}
          borderBottomWidth={1}
          shadow={0.5}
          borderBottomColor="muted.50">
          <Header
            backButtonTitle=""
            backgroundColor="white"
            textColor="primary.700"
            backButtonOnPress={onPress}
          />
        </Box>
      )}
      <ScrollView
        flex={1}
        alignSelf="center"
        alignContent="center"
        px={6}
        width={isDesktop ? '70%' : '100%'}>
        {isDesktop && (
          <Image
            alt="Ilustration"
            mt={6}
            mb={8}
            source={About}
            alignSelf="center"
            width={isDesktop ? '342px' : '263px'}
            height={isDesktop ? '240px' : '195px'}
          />
        )}

        <Text fontSize="24px" color="primary.900" fontWeight={700}>
          Today is about <TextNative style={styles.italic}>you</TextNative>
          <Text>{'\nto feel valued, respected, and prioritized.'}</Text>
        </Text>
        <Text color="primary.900" fontSize="md" mt={isDesktop ? 8 : 4}>
          Let's take a second and acknowledge you being here. You should be
          proud because life gets busy, yet you took the time to invest in
          yourself.
        </Text>
        <Text color="primary.900" fontSize="md" mt={6}>
          We don't take that lightly, so we are here to guide and walk alongside
          you. The following questions will help us get to know you more and
          recommend areas for you to focus on.
        </Text>

        {!isDesktop ? (
          <>
            <Image
              alt="Ilustration"
              mt={6}
              source={About}
              alignSelf="center"
              width={isDesktop ? '342px' : '263px'}
              height={isDesktop ? '240px' : '195px'}
            />
            <Button
              alignSelf="center"
              mt={4}
              height="48px"
              mb={10}
              _text={{ fontWeight: 500 }}
              onPress={() => navigation.navigate('VoyageScreener')}
              width="100%">
              Continue
            </Button>
          </>
        ) : (
          <HStack width="100%" space={3} mt={6}>
            <Button
              height="48px"
              variant="outline"
              mt={4}
              _text={{ color: 'primary.900', fontWeight: 500 }}
              onPress={() => navigation.navigate('Explore')}
              width="294px">
              Not now
            </Button>
            <Button
              _text={{ fontWeight: 500 }}
              height="48px"
              mt={4}
              onPress={() => navigation.navigate('VoyageScreener')}
              width="294px">
              Continue
            </Button>
          </HStack>
        )}
      </ScrollView>
    </>
  )
}

export default VoyagesAboutYou
