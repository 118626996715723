import { Button, Divider, HStack, Image, Text, useNativeBase, VStack } from 'native-base'
import { ImageBackground } from 'react-native'
import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import CabanaVertical from '../../assets/images/landing/CabanaVertical'
import FaIcon from '../../components/_shared/FaIcon'
import useAuthentication from '../../hooks/authentication/useAuthentication'
import { useNavigation } from '@react-navigation/native'
import { useEffect } from 'react'

const cabanaHorizontal = require('../../assets/images/landing/cabana-horizontal.svg')
const background = require('../../assets/images/landing/landing-background.png')

const LandingScreen = () => {
  const { isLoading, user, loginWithMagicLink, loginWithPassword, signup } = useAuthentication()
  const isMobile = useMobileBreakpoint()

  const navigation = useNavigation()
  useEffect(() => {
    if(!isLoading && user) navigation.replace('Home')
  }, [isLoading, user])

  return (
    <ImageBackground source={background} resizeMode="cover" style={{ flex: 1 }}>
      <VStack flex={1} alignItems={'center'} justifyContent={'center'}>
        {isMobile ? (
          <CabanaVertical />
        ) : (
          <Image
            style={{
              width: 550,
              height: 90,
            }}
            source={cabanaHorizontal}
            alt="Logo"
            mb={12}
            zIndex={100}
          />
        )}
        <VStack style={{ gap: 12 }} mt={isMobile && 10}>
          <Button height="48px" width="318px" onPress={loginWithMagicLink}>
            <HStack alignItems={'center'}>
              <FaIcon isSolid name="envelope" color={'white'} />
              <Text fontSize="16px" fontWeight={600} ml={2} color="white">
                Login with magic link
              </Text>
            </HStack>
          </Button>
          <Button height="48px" width="318px" onPress={loginWithPassword}>
            <HStack alignItems={'center'}>
              <FaIcon isSolid name="lock-keyhole" color={'white'} />
              <Text fontSize="16px" fontWeight={600} ml={2} color="white">
                Login with password
              </Text>
            </HStack>
          </Button>
          <HStack alignItems={'center'}>
            <Divider thickness={2} width={1} flexGrow={1} />
            <Text color={'white'} mx={4} fontWeight={'bold'}>
              OR
            </Text>
            <Divider thickness={2} width={1} flexGrow={1} />
          </HStack>
          <Button height="48px" width="318px" onPress={signup}>
            <HStack alignItems={'center'}>
              <FaIcon isSolid name="user-plus" color={'white'} />
              <Text fontSize="16px" fontWeight={600} ml={2} color="white">
                Create account
              </Text>
            </HStack>
          </Button>
        </VStack>
      </VStack>
    </ImageBackground>
  )
}

export default LandingScreen
