import { useNavigation } from '@react-navigation/native'
import LoadingScreen from '../LoadingScreen'
import { useEffect } from 'react'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

const LoginCallbackScreen = () => {
  const navigation = useNavigation()
  useEffect(() => {
    navigation.replace('Home')
  }, [])

  return <LoadingScreen />
}

export default LoginCallbackScreen
