import { useNavigation } from '@react-navigation/native'
import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import EmotionReview from '../../components/vibeCheck/EmotionReview'
import moods from '../../components/vibeCheck/Moods'
import VibeCheckContainer from '../../components/_shared/layout/VibeCheckContainer'
import FormLayout from '../../layouts/FormLayout'
import secureScreen from '../../hooks/authentication/secureScreen'

const VibeCheckReviewScreen = ({ navigation, route }) => {
  const { picked, comment, date } = route.params
  const mood = moods.find((x) => x.name === picked)
  const isMobile = useMobileBreakpoint()

  return isMobile ? (
    <VibeCheckView mood={mood} comment={comment} date={date} />
  ) : (
    <FormLayout padding="0" hasSafeAreaY={true}>
      <VibeCheckView mood={mood} comment={comment} date={date} />
    </FormLayout>
  )
}

const VibeCheckView = ({ mood, comment, date }) => {
  const navigation = useNavigation()
  return (
    <VibeCheckContainer
      mainContent={
        <>
          <EmotionReview
            emotion={mood}
            hasSubmittedReview={true}
            emotionNote={comment}
            date={date}
          />
        </>
      }
      date={date}
      goNextText="Done"
      goNext={() => navigation.goBack()}
      goBack={() => navigation.goBack()}
    />
  )
}

export default secureScreen(VibeCheckReviewScreen)
